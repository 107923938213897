import React from "react";

export function NaverIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 512 512"
      height="40px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      width="40px"
      xmlSpace="preserve"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ position: "relative", top: "6px", right: "5px" }}
    >
      <g>
        <path
          d="M510.689,478.854c0,17.561-14.275,31.836-31.836,31.836H33.146c-17.56,0-31.836-14.275-31.836-31.836   V33.146c0-17.56,14.276-31.836,31.836-31.836h445.707c17.561,0,31.836,14.276,31.836,31.836V478.854z"
          fill="#099A49"
        />
      </g>
      <g id="Layer_6">
        <g>
          <path
            d="M414.228,390.593H97.772c-18.865,0-34.221-16.784-34.221-37.428v-194.33    c0-20.629,15.355-37.427,34.221-37.427h316.455c18.865,0,34.222,16.79,34.222,37.427v194.33    C448.449,373.802,433.093,390.593,414.228,390.593z M97.772,154.616c-3.909,0-6.314,2.735-6.314,4.219v194.323    c0,1.483,2.405,4.212,6.314,4.212h316.455c3.91,0,6.314-2.729,6.314-4.212V158.835c0-1.479-2.404-4.204-6.314-4.204H97.772    V154.616z"
            fill="#FFFFFF"
          />
        </g>
        <polygon
          fill="#FFFFFF"
          points="427.411,147.824 257.413,147.824 87.641,147.881 68.867,172.842 255.459,283.788 445.96,172.94"
        />
      </g>
    </svg>
  );
}
